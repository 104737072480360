.ExportMenu {
    display: flex;
    flex-direction: column;

    font-size: 14px;
}

.ExportButton {
    background: hsla(0, 0, 0, 0.1);
    display: flex;
    align-items: center;

    border-radius: 2px;

    padding: 10px;
    white-space: nowrap;
}

.ExportButtonIcon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

.ExportButton:hover {
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
}
