@value topbarHeight from '../App.module.css';

.Sidebar {
    height: calc(100vh - topbarHeight);
    background-color: #1e2328;

    display: flex;
    flex-direction: column;

    font-size: 14px;
}

.FoldersContainer {
    height: 100%;
    overflow-y: scroll;
}