.react-calendar {
  width: 250px;
  max-width: 100%;
  background: transparent;
  color: white;
  border: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.125em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
}
.react-calendar__navigation__arrow {
  font-size: 20px;
  padding-bottom: 5px;
}
.react-calendar__navigation button {
  min-width: 30px;
  background: none;
  color: white;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}
.react-calendar__navigation button[disabled] {
  color: #4b4b4b;
  background-color: transparent;
}
.react-calendar__navigation__label {
  text-transform: uppercase;
  font-weight: 700 !important;
  font-size: 16px;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day {
  color: white;
}
.react-calendar__month-view__days__day--weekend {
  color: white;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #969696;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
  color: white;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: transparent;
  color: #4b4b4b;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: rgba(255, 255, 255, 0.1);
}
.react-calendar__tile--hasActive {
  background: rgba(255, 255, 255, 0.3);
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: rgba(255, 255, 255, 0.2);
}
.react-calendar__tile--active {
  background: rgba(255, 255, 255, 0.5);
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: rgba(255, 255, 255, 0.4);
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: rgba(255, 255, 255, 0.1);
}
