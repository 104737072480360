.ShareMenu {
    display: flex;
    flex-direction: column;

    font-size: 14px;
    padding: 0.5em;
}

.ShareText {
    white-space: nowrap;
}

.TextAreaContainer {
    position: relative;
}

.TextArea {
    margin-top: 1em;
    padding: 0.5em;
    padding-right: 2.5em;
    color: #ffffff;
    border: 1px solid #ffffffaa;
    border-radius: 5px;
    background-color: #ffffff00;
    outline: none;
    width: calc(100% - 3em);
}

.CopyButton {
    position: absolute;
    width: 25px;
    height: 25px;

    top: 17.5px;
    right: 2.5px;

    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.CopyButton:hover {
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
}

.CopyButtonIcon {
    width: 20px;
    height: 20px;
}
