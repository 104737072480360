.Item {
    display: flex;
    align-items: center;
    padding: 2px 0;
    cursor: pointer;
    width: 100%;
}

.ItemBullet {
    background-color: hsla(0,0%,100%,.1);;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    display: block;
    margin-right: 8px;

    transition: 0.1s linear all;
}

.Item:hover > .ItemBullet {
    background-color: hsla(0,0%,100%,.45);
}

.SelectedItem > .ItemBullet, .SelectedItem:hover > .ItemBullet {
    background-color: hsla(0,0%,100%,.8);
}

.ColorInput {
    width: 1px;
    height: 1px;
    z-index: -1;
}

.HelpItem {
    display: flex;
    align-items: center;
    position: relative;
}

.HelpIcon {
    width: 16px;
    margin-left: 5px;

    position: absolute;
    right: 0;
}

.ColorIcon {
    width: 14px;
    margin-left: 5px;

    position: absolute;
    right: 0;
}

.HelpIcon > g > g {
    fill: rgba(255, 255, 255, 0);
    transition: fill 0.2s;
}

.HelpItem:hover > .HelpIcon > g > g, .ColorIcon > g > g {
    fill: rgba(255, 255, 255, 0.3);
}

.HelpIcon:hover > g > g, .ColorIcon:hover > g > g {
    fill: rgba(255, 255, 255, 0.8) !important;
}

.HelpIcon:hover, .ColorIcon:hover {
    cursor: pointer;
}