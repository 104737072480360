.Loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Spinner {
    display: inline-block;
    vertical-align: text-bottom;
    border: .25em solid;
    border-right-color: transparent !important;
    border-radius: 50%;
    -webkit-animation: Spinner .5s linear infinite;
    animation: Spinner .5s linear infinite;
}

@keyframes Spinner {
    100% {
        transform: rotate(360deg);
    }
}

.Text {
    margin-left: 10px;
}