.Folder {
    display: flex;
    flex-direction: column;

    padding: 5px 0;
}

.FolderName {
    color: #fff;

    cursor: pointer;

    display: flex;
    align-items: center;
}

.Items {
    padding-left: 10px;
    margin-left: 0.5em;
    margin-top: 5px;

    border-left: 1px solid hsla(0,0%,100%,.3);
}

.Arrow {
    width: 1em;
    height: 1em;
    margin-right: 2px;
    transform: rotate(-90deg);

    transition: 0.1s all linear;
}

.CollapsedArrow {
    transform: rotate(0deg)
}