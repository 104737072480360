.TopBarMenu {
    color: #fff;
    position: relative;

    display: flex;
    align-items: center;
}

.Button {
    display: flex;
    align-items: center;

    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    transition: background 0.15s;

    margin: 15px 5px;
    padding: 10px;
    border: 1px solid hsla(0, 0%, 100%, 0.1);
}

.ButtonDisabled {
    color: hsla(0, 0%, 100%, 0.1) !important;
    cursor: default !important;
}

.ButtonDisabled > .ButtonIcon > g > g,
.ButtonDisabled > .ButtonIcon,
.ButtonDisabled > .ButtonArrow > g > g {
    fill: hsla(0, 0%, 100%, 0.1);
}

.Button:not(.ButtonDisabled):hover {
    background: hsla(0, 0%, 100%, 0.05);
    cursor: pointer;
}

.ButtonText {
    margin: 0 10px;
}

.ButtonIcon,
.ButtonArrow {
    width: 16px;
    height: 16px;
}

.ButtonArrow {
    transform: rotate(0deg);
    transition: transform 50ms ease;
}

.ButtonActive {
    background: hsla(0, 0%, 100%, 0.1) !important;
}

.ButtonActive > .ButtonArrow {
    transform: rotate(180deg);
    transition: transform 50ms ease;
}

.MenuContent {
    position: absolute;
    top: 60px;
    right: 5px;

    border: 1px solid hsla(0, 0%, 100%, 0.1);
    border-radius: 5px;

    padding: 5px;

    z-index: 1001;

    background: #35393e;
}

.MenuContent.MenuHidden {
    visibility: hidden;
    opacity: 0;
}
