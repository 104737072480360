body {
  margin: 0;
  padding: 0;
  font-family: "Univers Next W02", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;
}

input, textarea, select, button {
  font: inherit;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: hsla(0,0%,100%,.3);
}

::-webkit-scrollbar-track {
  background: transparent;
}