.Folders {
    padding: 1em;
    color: #fff;
}

.FoldersHeader {
    text-transform: uppercase;
    margin-bottom: 10px;
    height: 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.FoldersHelpImage {
    width: 16px;
    margin-left: 5px;
}

.FoldersHelpImage > g > g {
    fill: rgba(255, 255, 255, 0.3);
    transition: fill 0.2s;
}

.FoldersHelpImage:hover > g > g {
    fill: rgba(255, 255, 255, 0.8);
}

.FoldersHelpImage:hover {
    cursor: pointer;
}

.FoldersContainer {
    padding-left: 0.5em;
}