@value sidebarWidth, topbarHeight from '../App.module.css';

.TopBar {
    display: inline-flex;
    background-color: #1e2328;
    height: topbarHeight;
}

.Logo {
    height: calc(topbarHeight - 30px);
    margin: 15px;
    min-width: calc(sidebarWidth - 60px);
    max-width: calc(sidebarWidth - 60px);
    width: calc(250px - 60px);
}

.ControlsContainer {
    width: 100%;

    padding: 0 10px 0 0;

    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.Onboarding {
    position: absolute;

    top: topbarHeight;
    left: sidebarWidth;

    width: calc(100% - sidebarWidth);
    height: calc(topbarHeight + 30px);
}