.TopBarMenu_TopBarMenu__33Xrq {
    color: #fff;
    position: relative;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.TopBarMenu_Button__2Ddsx {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;

    border-radius: 5px;
    color: #fff;
    font-size: 14px;
    transition: background 0.15s;

    margin: 15px 5px;
    padding: 10px;
    border: 1px solid hsla(0, 0%, 100%, 0.1);
}

.TopBarMenu_ButtonDisabled__2if54 {
    color: hsla(0, 0%, 100%, 0.1) !important;
    cursor: default !important;
}

.TopBarMenu_ButtonDisabled__2if54 > .TopBarMenu_ButtonIcon__1V-8p > g > g,
.TopBarMenu_ButtonDisabled__2if54 > .TopBarMenu_ButtonIcon__1V-8p,
.TopBarMenu_ButtonDisabled__2if54 > .TopBarMenu_ButtonArrow__3XAiL > g > g {
    fill: hsla(0, 0%, 100%, 0.1);
}

.TopBarMenu_Button__2Ddsx:not(.TopBarMenu_ButtonDisabled__2if54):hover {
    background: hsla(0, 0%, 100%, 0.05);
    cursor: pointer;
}

.TopBarMenu_ButtonText__qtXqC {
    margin: 0 10px;
}

.TopBarMenu_ButtonIcon__1V-8p,
.TopBarMenu_ButtonArrow__3XAiL {
    width: 16px;
    height: 16px;
}

.TopBarMenu_ButtonArrow__3XAiL {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    transition: -webkit-transform 50ms ease;
    transition: transform 50ms ease;
    transition: transform 50ms ease, -webkit-transform 50ms ease;
}

.TopBarMenu_ButtonActive__1XH2e {
    background: hsla(0, 0%, 100%, 0.1) !important;
}

.TopBarMenu_ButtonActive__1XH2e > .TopBarMenu_ButtonArrow__3XAiL {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    transition: -webkit-transform 50ms ease;
    transition: transform 50ms ease;
    transition: transform 50ms ease, -webkit-transform 50ms ease;
}

.TopBarMenu_MenuContent__32M7i {
    position: absolute;
    top: 60px;
    right: 5px;

    border: 1px solid hsla(0, 0%, 100%, 0.1);
    border-radius: 5px;

    padding: 5px;

    z-index: 1001;

    background: #35393e;
}

.TopBarMenu_MenuContent__32M7i.TopBarMenu_MenuHidden__KajcV {
    visibility: hidden;
    opacity: 0;
}

.ExportMenu_ExportMenu__3uvT5 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;

    font-size: 14px;
}

.ExportMenu_ExportButton__2NiZF {
    background: hsla(0, 0, 0, 0.1);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;

    border-radius: 2px;

    padding: 10px;
    white-space: nowrap;
}

.ExportMenu_ExportButtonIcon__cKZYu {
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

.ExportMenu_ExportButton__2NiZF:hover {
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
}

.ShareMenu_ShareMenu__2qCw- {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;

    font-size: 14px;
    padding: 0.5em;
}

.ShareMenu_ShareText__34uqQ {
    white-space: nowrap;
}

.ShareMenu_TextAreaContainer__1c6JK {
    position: relative;
}

.ShareMenu_TextArea__29tY- {
    margin-top: 1em;
    padding: 0.5em;
    padding-right: 2.5em;
    color: #ffffff;
    border: 1px solid #ffffffaa;
    border-radius: 5px;
    background-color: #ffffff00;
    outline: none;
    width: calc(100% - 3em);
}

.ShareMenu_CopyButton__2cH_U {
    position: absolute;
    width: 25px;
    height: 25px;

    top: 17.5px;
    right: 2.5px;

    border-radius: 5px;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.ShareMenu_CopyButton__2cH_U:hover {
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
}

.ShareMenu_CopyButtonIcon__3QQA1 {
    width: 20px;
    height: 20px;
}

.react-calendar {
  width: 250px;
  max-width: 100%;
  background: transparent;
  color: white;
  border: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.125em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
}
.react-calendar__navigation__arrow {
  font-size: 20px;
  padding-bottom: 5px;
}
.react-calendar__navigation button {
  min-width: 30px;
  background: none;
  color: white;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}
.react-calendar__navigation button[disabled] {
  color: #4b4b4b;
  background-color: transparent;
}
.react-calendar__navigation__label {
  text-transform: uppercase;
  font-weight: 700 !important;
  font-size: 16px;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day {
  color: white;
}
.react-calendar__month-view__days__day--weekend {
  color: white;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #969696;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
  color: white;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: transparent;
  color: #4b4b4b;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: rgba(255, 255, 255, 0.1);
}
.react-calendar__tile--hasActive {
  background: rgba(255, 255, 255, 0.3);
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: rgba(255, 255, 255, 0.2);
}
.react-calendar__tile--active {
  background: rgba(255, 255, 255, 0.5);
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: rgba(255, 255, 255, 0.4);
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.react-date-picker {
  display: -webkit-inline-flex;
  display: inline-flex;
  position: relative;
}
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  box-sizing: border-box;
}
.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}
.react-date-picker__wrapper {
  display: -webkit-flex;
  display: flex;
  border: 0;
  margin: auto;
}
.react-date-picker__inputGroup {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  display: -webkit-flex;
  display: flex;
  padding: 0 2px;
  -webkit-align-items: baseline;
          align-items: baseline;
  box-sizing: content-box;
}
.react-date-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
  color: white;
}
.react-date-picker__inputGroup__input {
  min-width: 1ch;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
  color: white;
  font-weight: 500;
}
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.7);
}
.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -1ch;
  padding-left: calc(1px +  1ch);
}

.Selector_Selector__3vzkh {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;

    font-size: 14px;
    transition: all .15s;
}

.Selector_Option__1CCZ_ {
    text-transform: capitalize;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    
    cursor: pointer;
}

.Selector_OptionBullet__38Gab {
    background-color: hsla(0,0%,100%,.1);;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    display: block;
    margin-bottom: 5px;

    transition: 0.1s linear all;
}

.Selector_Option__1CCZ_:not(.Selector_SelectedOption__3Kh27):hover > .Selector_OptionBullet__38Gab {
    background-color: hsla(0,0%,100%,.45);    
}

.Selector_SelectedOption__3Kh27 > .Selector_OptionBullet__38Gab {
    background: rgba(255,255,255,1);
}
.ResolutionSelector_MenuContent__2Knj2 {
}

.ResolutionSelector_MenuSection__1I7eO {
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0.5em;
}

.ResolutionSelector_MenuSection__1I7eO:not(:last-child) {
    margin-bottom: 5px;
}

.ResolutionSelector_MenuSectionTitle__20OsR {
    text-transform: uppercase;
    font-size: 12px;

    margin-bottom: 20px;
}

.ResolutionSelector_DateInputContainer__1-Y_w {
    width: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;

    margin-bottom: 15px;
}

.ResolutionSelector_DateInput__1nV68 {
    width: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.ResolutionSelector_DateInputSeparator__1g7Vd {
    font-size: 24px;
    margin-top: -4px;
}

.ResolutionSelector_Picker__1kwPr {
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    font-size: 18px;
}

.ResolutionSelector_Calendar__2qrLc {
    padding: 0 10px;
    font-size: 14px;
}

.App_App__3RYH7 {
  height: 100%;

  display: grid;
  grid-template-rows: 75px 1fr;
  grid-template-columns: 250px 1fr;

  grid-template-areas:
      "topbar topbar"
      "sidebar chart";
}

.App_Blurred__32vbg {
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

.App_TopBar__E0iaQ {
  grid-area: topbar;
}

.App_Sidebar__3QV3q {
  grid-area: sidebar;
}

.App_Chart__2-lkG {
  grid-area: chart;
}

.TopBar_TopBar__2EMi- {
    display: -webkit-inline-flex;
    display: inline-flex;
    background-color: #1e2328;
    height: 75px;
}

.TopBar_Logo__27AOG {
    height: calc(75px - 30px);
    margin: 15px;
    min-width: calc(250px - 60px);
    max-width: calc(250px - 60px);
    width: calc(250px - 60px);
}

.TopBar_ControlsContainer__1GKgt {
    width: 100%;

    padding: 0 10px 0 0;

    display: -webkit-flex;

    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
}

.TopBar_Onboarding__VW0IU {
    position: absolute;

    top: 75px;
    left: 250px;

    width: calc(100% - 250px);
    height: calc(75px + 30px);
}
.Loader_Loader__38Tyt {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.Loader_Spinner__3FxAm {
    display: inline-block;
    vertical-align: text-bottom;
    border: .25em solid;
    border-right-color: transparent !important;
    border-radius: 50%;
    -webkit-animation: Loader_Spinner__3FxAm .5s linear infinite;
    animation: Loader_Spinner__3FxAm .5s linear infinite;
}

@-webkit-keyframes Loader_Spinner__3FxAm {
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes Loader_Spinner__3FxAm {
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.Loader_Text__2Me5e {
    margin-left: 10px;
}
.Item_Item__2Rs1E {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 2px 0;
    cursor: pointer;
    width: 100%;
}

.Item_ItemBullet__2fzd- {
    background-color: hsla(0,0%,100%,.1);;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    display: block;
    margin-right: 8px;

    transition: 0.1s linear all;
}

.Item_Item__2Rs1E:hover > .Item_ItemBullet__2fzd- {
    background-color: hsla(0,0%,100%,.45);
}

.Item_SelectedItem__2K5EJ > .Item_ItemBullet__2fzd-, .Item_SelectedItem__2K5EJ:hover > .Item_ItemBullet__2fzd- {
    background-color: hsla(0,0%,100%,.8);
}

.Item_ColorInput__3RoaC {
    width: 1px;
    height: 1px;
    z-index: -1;
}

.Item_HelpItem__jRTAP {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    position: relative;
}

.Item_HelpIcon__T1jzO {
    width: 16px;
    margin-left: 5px;

    position: absolute;
    right: 0;
}

.Item_ColorIcon__bbpI1 {
    width: 14px;
    margin-left: 5px;

    position: absolute;
    right: 0;
}

.Item_HelpIcon__T1jzO > g > g {
    fill: rgba(255, 255, 255, 0);
    transition: fill 0.2s;
}

.Item_HelpItem__jRTAP:hover > .Item_HelpIcon__T1jzO > g > g, .Item_ColorIcon__bbpI1 > g > g {
    fill: rgba(255, 255, 255, 0.3);
}

.Item_HelpIcon__T1jzO:hover > g > g, .Item_ColorIcon__bbpI1:hover > g > g {
    fill: rgba(255, 255, 255, 0.8) !important;
}

.Item_HelpIcon__T1jzO:hover, .Item_ColorIcon__bbpI1:hover {
    cursor: pointer;
}
.Legend_Legend__3zb79 {
    background-color: hsla(0,0%,100%,.1);

    padding: 1em;

    color: white;
}
.Folder_Folder__2y1Kz {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;

    padding: 5px 0;
}

.Folder_FolderName__27Hh1 {
    color: #fff;

    cursor: pointer;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.Folder_Items__2QVAk {
    padding-left: 10px;
    margin-left: 0.5em;
    margin-top: 5px;

    border-left: 1px solid hsla(0,0%,100%,.3);
}

.Folder_Arrow__3pWT6 {
    width: 1em;
    height: 1em;
    margin-right: 2px;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);

    transition: 0.1s all linear;
}

.Folder_CollapsedArrow__2TphG {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg)
}
.Folders_Folders__39kSL {
    padding: 1em;
    color: #fff;
}

.Folders_FoldersHeader__2_8CG {
    text-transform: uppercase;
    margin-bottom: 10px;
    height: 16px;

    display: -webkit-flex;

    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.Folders_FoldersHelpImage__24pjc {
    width: 16px;
    margin-left: 5px;
}

.Folders_FoldersHelpImage__24pjc > g > g {
    fill: rgba(255, 255, 255, 0.3);
    transition: fill 0.2s;
}

.Folders_FoldersHelpImage__24pjc:hover > g > g {
    fill: rgba(255, 255, 255, 0.8);
}

.Folders_FoldersHelpImage__24pjc:hover {
    cursor: pointer;
}

.Folders_FoldersContainer__2aSpy {
    padding-left: 0.5em;
}
.Sidebar_Sidebar__vMBzL {
    height: calc(100vh - 75px);
    background-color: #1e2328;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;

    font-size: 14px;
}

.Sidebar_FoldersContainer__2pww2 {
    height: 100%;
    overflow-y: scroll;
}
.Chart_Chart__1wUF_ {
    height: calc(100vh - 75px);
    width: calc(100vw - 250px);
    background-color: #f0f1f2;

    position: relative;
}

.Chart_ChartContainer__25-SD {
    width: 100%;
    height: 100%;
}

.Chart_EmptyChartFallback__rbi6C {
    width: 100%;
    height: 100%;

    display: -webkit-flex;

    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;

    color: #9f9f9f;
}

.Chart_EmptyChartFallback__rbi6C > * {
    margin: 4px;
}

.Chart_barIcon__35-73 {
    width: 4em;
    height: 4em;
    fill: #9f9f9f;
}

.SmallScreen_SmallScreen__1g-Qc {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;

    background-color: #1e2328;
    width: 100%;
    height: 100%;
}

.SmallScreen_Logo__1BMqL {
    margin: 30px;
    max-width: 500px;
}

.SmallScreen_Text__12Gpo {
    color: white;
    margin: 30px;
    font-size: 15pt;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Univers Next W02", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;
}

input, textarea, select, button {
  font: inherit;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: hsla(0,0%,100%,.3);
}

::-webkit-scrollbar-track {
  background: transparent;
}
