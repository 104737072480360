.MenuContent {
}

.MenuSection {
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 0.5em;
}

.MenuSection:not(:last-child) {
    margin-bottom: 5px;
}

.MenuSectionTitle {
    text-transform: uppercase;
    font-size: 12px;

    margin-bottom: 20px;
}

.DateInputContainer {
    width: 100%;

    display: flex;
    justify-content: space-around;

    margin-bottom: 15px;
}

.DateInput {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.DateInputSeparator {
    font-size: 24px;
    margin-top: -4px;
}

.Picker {
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    font-size: 18px;
}

.Calendar {
    padding: 0 10px;
    font-size: 14px;
}
