.Selector {
    display: flex;
    justify-content: space-evenly;

    font-size: 14px;
    transition: all .15s;
}

.Option {
    text-transform: capitalize;

    display: flex;
    flex-direction: column;
    align-items: center;
    
    cursor: pointer;
}

.OptionBullet {
    background-color: hsla(0,0%,100%,.1);;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    display: block;
    margin-bottom: 5px;

    transition: 0.1s linear all;
}

.Option:not(.SelectedOption):hover > .OptionBullet {
    background-color: hsla(0,0%,100%,.45);    
}

.SelectedOption > .OptionBullet {
    background: rgba(255,255,255,1);
}