@value sidebarWidth, topbarHeight from "../App.module.css";

.Chart {
    height: calc(100vh - topbarHeight);
    width: calc(100vw - sidebarWidth);
    background-color: #f0f1f2;

    position: relative;
}

.ChartContainer {
    width: 100%;
    height: 100%;
}

.EmptyChartFallback {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: #9f9f9f;
}

.EmptyChartFallback > * {
    margin: 4px;
}

.barIcon {
    width: 4em;
    height: 4em;
    fill: #9f9f9f;
}
