.SmallScreen {
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-color: #1e2328;
    width: 100%;
    height: 100%;
}

.Logo {
    margin: 30px;
    max-width: 500px;
}

.Text {
    color: white;
    margin: 30px;
    font-size: 15pt;
}