@value sidebarWidth: 250px;
@value topbarHeight: 75px;

.App {
  height: 100%;

  display: grid;
  grid-template-rows: topbarHeight 1fr;
  grid-template-columns: sidebarWidth 1fr;

  grid-template-areas:
      "topbar topbar"
      "sidebar chart";
}

.Blurred {
  filter: blur(2px);
}

.TopBar {
  grid-area: topbar;
}

.Sidebar {
  grid-area: sidebar;
}

.Chart {
  grid-area: chart;
}
